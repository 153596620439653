<template>
	<v-row align="center" justify="center">
		<v-col cols="12" sm="8" md="7" lg="6" xl="5">
			<base-form-stepper ref="RegistrationForm"
				formTitle="Formulario de Registro"
				:step="step"
				:steps="steps"
				:formLoading="formLoading"
				hide-subheading
				text-save-button="Registrar"
				:hide-cancel-button="step <= 1"
				@prevStepButton="step--"
				@nextStepButton="nextStep"
				@cancelButton="close"
				@saveButton="save"
				:hideSaveButton="hideActions"
				:hideCancelButton="hideActions"
			>
				<template v-slot:formContent-1>
					<v-row>
						<v-col cols="12" sm="6" md="6">
							<base-text-field v-model="editedCompany.visualName" label="Nombre Empresa/Negocio" counter maxlength="100" 
							:rules="[commonValRules.required, validationRules.visualNameRules.counter]" required></base-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="6">
							<base-select v-model="editedCompany.businessSectorId" :items="businessSectors" label="Rubro" 
							:rules="[commonValRules.required]" required></base-select>
						</v-col>
						<v-col cols="12" sm="6" md="6">
							<base-select v-model="editedCompany.branchOfficeCountryId" :items="countries" label="País" @change="loadCities"
							:rules="[commonValRules.required]" required></base-select>
						</v-col>
						<v-col cols="12" sm="6" md="6">
							<base-select v-model="editedCompany.branchOfficeCityId" :items="availableCities" label="Ciudad" 
							:rules="[commonValRules.required]" required></base-select>
						</v-col>
						<!-- <v-col cols="12" sm="6" md="6">
							<base-textarea outlined v-model="editedCompany.branchOfficeAddress" label="Dirección" counter maxlength="300"  placeholder="Zona, Avenida/Calle, Número de puerta"
							:rules="[commonValRules.required, validationRules.branchOfficeAddressRules.counter]" required></base-textarea>
						</v-col> -->

						<!-- <v-col cols="12" sm="6" md="4">
							<base-text-field v-model="editedCompany.branchOfficeAddress.township" label="Municipio" counter maxlength="20" 
							:rules="[commonValRules.required]" required></base-text-field>
						</v-col> -->

						<v-col cols="12" sm="6" md="3">
							<base-text-field v-model="editedCompany.branchOfficeAddress.zone" label="Zona" counter maxlength="20" 
							:rules="[commonValRules.required]" required></base-text-field>
						</v-col>

						<v-col cols="12" sm="6" md="5">
							<base-text-field v-model="editedCompany.branchOfficeAddress.streetOrAvenue" label="Calle/Avenida" counter maxlength="50" 
							:rules="[commonValRules.required]" required></base-text-field>
						</v-col>

						<v-col cols="12" sm="6" md="4">
							<base-text-field v-model="editedCompany.branchOfficeAddress.number" label="Número de puerta" counter maxlength="10" 
							:rules="[commonValRules.required]" required></base-text-field>
						</v-col>


						<v-col cols="12" sm="6" md="6">
							<base-time-range-picker-dialog v-model="editedCompany.customerServiceSchedule" label="Horario de atención" counter maxlength="100" 
							:rules="[commonValRules.required, validationRules.customerServiceScheduleRules.counter]" required></base-time-range-picker-dialog>
						</v-col>
						<v-col cols="12">
							<v-radio-group 
								v-model="editedCompany.servicePlanId"
								label="*Seleccione un plan"
								:rules="[commonValRules.required]" required
							>
								<v-radio v-for="plan in servicePlans" :key="plan.id"
									:label="`${plan.visualName} (${plan.details})`"
									:value="plan.id"
									color="info"
								></v-radio>
							</v-radio-group>
						</v-col>
					</v-row>
				</template>

				<template v-slot:formContent-2>
					<v-row no-gutters>
						<v-col cols="12">
							<base-map ref="RegistrationMap" v-model="editedCompany.branchOfficeCoordinates" title="Indique la ubicación de su empresa/negocio." :searchAddress="searchMapAddress" />
						</v-col>
					</v-row>
				</template>

				<template v-slot:formContent-3>
					<v-window v-model="finalStep" touchless>
						<v-window-item :value="1">
							<v-row justify="center">
								<v-col cols="12" sm="4" md="4">
									<base-select v-model="editedItem.nationalityId" :items="nationalities" label="Nacionalidad" 
									:rules="[commonValRules.required]" required></base-select>
								</v-col>
								<v-col cols="12" sm="4" md="4">
									<base-text-field v-model="editedItem.name" label="Nombres" counter :maxlength="person.nameMaxLength" 
									:rules="[commonValRules.required, personValidationRules.nameRules.counter]" required></base-text-field>
								</v-col>
								<v-col cols="12" sm="4" md="4">
									<base-text-field v-model="editedItem.surnames" label="Apellidos" counter :maxlength="person.surnamesMaxLength" 
									:rules="[commonValRules.required, personValidationRules.surnamesRules.counter]" required></base-text-field>
								</v-col>

								<v-col cols="12" sm="4" md="4">
									<base-date-picker-dialog birthdateMode v-model="editedItem.birthdate" label="Fecha de Nacimiento" counter :maxlength="person.birthdateMaxLength" 
									:rules="[commonValRules.required, personValidationRules.birthdateRules.counter]" required></base-date-picker-dialog>
								</v-col>
								<v-col cols="12" sm="4" md="4">
									<base-label text="*Género">
										<v-radio-group class="mt-0 pt-0"
											v-model="editedItem.gender"
											row
											:rules="[commonValRules.required]" required
										>
											<v-radio v-for="gender in genders" :key="gender.id"
												:label="gender.visualName"
												:value="gender.id"
												color="info"
											></v-radio>
										</v-radio-group>
									</base-label>
								</v-col>
							</v-row>
                  
							<v-row>
								<v-col cols="12" sm="4" md="4">
									<base-select v-model="editedItem.documentId" :items="documents" label="Tipo Documento"
									:rules="[commonValRules.required]" required></base-select>
								</v-col>
								<v-col cols="12" sm="3" md="3">
									<base-number-field fullWidth v-model="editedItem.documentNumber" label="Número Documento" counter :maxlength="person.documentNumberMaxLength"
									:rules="[personValidationRules.documentNumberRules.counter]" required></base-number-field>
								</v-col>
								<v-col cols="12" sm="2" md="2">
									<base-text-field v-model="editedItem.documentComplement" label="Complemento" counter :maxlength="person.documentComplementMaxLength"
									:rules="[personValidationRules.documentComplementRules.counter]"></base-text-field>
								</v-col>
								<v-col cols="12" sm="3" md="3">
									<base-select v-model="editedItem.documentExtensionId" :items="documentExtensions" label="Extensión" 
									:rules="documentExtensionIdRules" :required="documentExtensionIsRequired" :disabled="!documentExtensionIsRequired"></base-select>
								</v-col>

								<v-col cols="12" sm="4" md="4">
									<base-number-field fullWidth v-model="editedItem.cellPhoneNumber" label="Número Celular" counter :maxlength="person.cellPhoneNumberMaxLength" 
									:rules="[personValidationRules.cellPhoneNumberRules.counter]" required></base-number-field>
								</v-col>
								<v-col cols="12" sm="8" md="8">
									<base-text-field v-model="editedItem.email" label="Correo Electrónico" counter :maxlength="person.emailMaxLength"  
									:rules="[commonValRules.required, commonValRules.email, personValidationRules.emailRules.counter]" required></base-text-field>
								</v-col>

								<!-- <v-col cols="12" sm="6" md="6">
									<base-text-field
										v-model="editedItem.password"
										label="Contraseña"
										hint="Al menos 8 caracteres"
										:append-icon="showPwd1 ? 'mdi-eye' : 'mdi-eye-off'"
										:type="showPwd1 ? 'text' : 'password'"
										maxlength="25"
										@click:append="showPwd1 = !showPwd1"
										:rules="[commonValRules.required, validationRules.passwordRules.counter]"
										required
									></base-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="6">
									<base-text-field
										v-model="editedItem.confirmPassword"
										label="Confirmar Contraseña"
										hint="Al menos 8 caracteres"
										:append-icon="showPwd2 ? 'mdi-eye' : 'mdi-eye-off'"
										:type="showPwd2 ? 'text' : 'password'"
										maxlength="25"
										@click:append="showPwd2 = !showPwd2"
										:rules="[commonValRules.required, validationRules.passwordRules.counter, validationRules.passwordRules.match]"
										required
									></base-text-field>
								</v-col> -->
							</v-row>
						</v-window-item>
						<v-window-item :value="2">
							<v-row align="center" justify="center">
								<v-col cols="12" align="center">
									<v-icon size="86px" color="success">mdi-checkbox-marked-circle</v-icon>
									<h2 class="grey--text">¡El registro fue exitoso!</h2><br>
									<h3 class="grey--text">
										Se envió un correo electrónico a la dirección <strong>{{editedItem.email}}</strong> con los pasos a seguir para que usted pueda acceder a la plataforma <strong>GAVCOM</strong>. Por favor revise su bandeja de entrada y la bandeja de correo no deseado.
										<br>
										<br>
										¡Bienvenido al sistema GAVCOM!
									</h3>
								</v-col>
							</v-row>
						</v-window-item>
					</v-window>
				</template>
			</base-form-stepper>
		</v-col>

		<base-loader />
	</v-row>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'
import PersonData from '@/mixins/person-data'

export default {
	mixins: [MainExternalData, PersonData],

	data() {
		return {
			step: 1,
      steps:[
        {value: 1, text:'Empresa o Negocio'},
        {value: 2, text:'Geolocalización'},
				{value: 3, text:'Datos Personales'}
			],
			finalStep:1,
			servicePlans:[],
			businessSectors:[],
			countries:[],
      nationalities:[],
			cities:[],
			availableCities:[],
			documentExtensionIsRequired: false,
			editedCompany: {
        visualName: '',
        businessSectorId: 0,
				servicePlanId: 0,
				branchOfficeCountryId: 0,
				branchOfficeCityId: '',
				branchOfficeAddress: {
					zone: '',
					streetOrAvenue: '',
					number: '',
				},
				customerServiceSchedule: '',
				branchOfficeCoordinates: '',
      },
      defaultCompany: {
        visualName: '',
        businessSectorId: 0,
				servicePlanId: 0,
				branchOfficeCountryId: 0,
				branchOfficeCityId: '',
				branchOfficeAddress: {
					zone: '',
					streetOrAvenue: '',
					number: '',
				},
				customerServiceSchedule: '',
				branchOfficeCoordinates: '',
			},

			hideActions: false,

			gdpiCode:'',
			initialUserForm: true,
			selectForm: true,
			searchPerson: {
        gdpi: '',
        email: '',
        fullName: '',
        exists: false
      },
      defaultSearchPerson: {
        gdpi: '',
        email: '',
        fullName: '',
        exists: false
      },

			editedItem: {
        nationalityId: 0,
        name: '',
				surnames: '',
				gender:'',
				birthdate: '',
				documentId: '',
        documentNumber: '',
        documentComplement: '',
        documentExtensionId: '',
				cellPhoneNumber: '',
				email: '',
				password: '',
				confirmPassword: '',
      },
      defaultItem: {
        nationalityId: 0,
        name: '',
				surnames: '',
				gender:'',
				birthdate: '',
				documentId: '',
        documentNumber: '',
        documentComplement: '',
        documentExtensionId: '',
				cellPhoneNumber: '',
				email: '',
				password: '',
				confirmPassword: '',
			},
			formLoading: false,
			showPwd1: false,
			showPwd2: false,
			validationRules: {
				gdpiCodeRules: {
          counter: value => (!!value && value.length <= 30) || 'No debe tener más de 30 caracteres.',
				},

				visualNameRules: {
          counter: value => (value.length >= 5 && value.length <= 100) || 'No debe tener más de 100 caracteres, ni menos de 5 caracteres.',
        },
        branchOfficeAddressRules: {
          counter: value => (value.length <= 300) || 'No debe tener más de 300 caracteres.',
				},
				customerServiceScheduleRules: {
          counter: value => (!!value && value.length <= 100) || 'No debe tener más de 100 caracteres.',
				},
				branchOfficeCoordinatesRules: {
          counter: value => (value.length <= 150) || 'No debe tener más de 150 caracteres.',
				},

				passwordRules: {
					counter: value => (value.length >= 8 && value.length <= 25) || 'No debe tener más de 25 caracteres, ni menos de 8 caracteres.',
					match: value => (value === this.editedItem.password) || 'No coincide con la contraseña.',
        },
			}
		}
	},

	computed: {
		branchOfficeFullAddress() {
			return `Zona: ${this.editedCompany.branchOfficeAddress.zone}, ${this.editedCompany.branchOfficeAddress.streetOrAvenue}, Nro. ${this.editedCompany.branchOfficeAddress.number}`;
		},
		searchMapAddress(){
			this.editedCompany.branchOfficeCoordinates = ''
			if (this.editedCompany.branchOfficeCountryId > 0 && this.editedCompany.branchOfficeCityId) {
				const country = this.countries.find(x => x.value == this.editedCompany.branchOfficeCountryId).text
				const city = this.cities.find(x => x.id == this.editedCompany.branchOfficeCityId).visualName
				return `${this.editedCompany.branchOfficeAddress.streetOrAvenue} ${this.editedCompany.branchOfficeAddress.number}, ${city}, ${country}`
			} 
			return null
		},

		documentExtensions() {
			return this.editedItem.documentId == this.$Const.DOCUMENT_TYPE.CI ? this.extensions : [];
    },

		documentExtensionIdRules(){
      var rules=[];
			if (this.editedItem.documentId === this.$Const.DOCUMENT_TYPE.CI) {
        rules.push(this.commonValRules.required);
        this.documentExtensionIsRequired = true;
      }
      else {
        this.editedItem.documentExtensionId = '';
        this.documentExtensionIsRequired = false;
      }
			return rules;
		},
	},

	created() {
	},

	async mounted() {
		this.SHOW_LOADING()
		this.formLoading = true;
		await this.listOfParameters();
		await this.listOfCountries();
    await this.listOfNationalities();
    await this.listOfCities();
		await this.listOfServicePlans();
		await this.listOfBusinessSectors();
		this.HIDE_LOADING()
		this.formLoading = false;
	},

	methods: {
		async listOfParameters(){
      let me=this;
			await me.getListOfParameterValuesResponse([me.$Const.PARAMETERS.DOCUMENTS, me.$Const.PARAMETERS.EXTENSIONS]).then(data => {
        if (data !== undefined) {
          let documentArray = data.find(x => x.id == me.$Const.PARAMETERS.DOCUMENTS).values;
          documentArray.map(function(x){
            me.documents.push({text:x.visualName, value:x.id});
          });

          let extensionsArray = data.find(x => x.id == me.$Const.PARAMETERS.EXTENSIONS).values;
          extensionsArray.map(function(x){
            me.extensions.push({text:x.visualName, value:x.id});
          });
        }
      }).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
    },

		async listOfCountries(){
      let me=this;
			var countriesArray=[];
			await me.getObjectResponseWithoutHeaders('api/Parameter/Countries', me.baseRequest).then(data => {
				if (data !== undefined) {
					countriesArray = data.countries; 
					countriesArray.map(function(x){
            me.countries.push({text:x.visualName, value:x.id});
          });
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
    },

    async listOfNationalities(){
      let me=this;
			var nationalitiesArray=[];
			await me.getObjectResponseWithoutHeaders('api/Parameter/Nationalities', me.baseRequest).then(data => {
				if (data !== undefined) {
					nationalitiesArray = data.nationalities; 
					nationalitiesArray.map(function(x){
            me.nationalities.push({text:x.visualName, value:x.id});
          });
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
    },
    
    async listOfCities(){
      let me=this;
			await me.getObjectResponseWithoutHeaders('api/Parameter/Cities', me.baseRequest).then(data => {
				if (data !== undefined) {
					me.cities = data.cities; 
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
		},

		loadCities(){
      this.availableCities=[];
      this.editedCompany.branchOfficeCityId='';
      if (!this.editedCompany.branchOfficeCountryId || this.editedCompany.branchOfficeCountryId === 0) {
        return;
      }

      const available = this.cities.filter(x => x.countryId === this.editedCompany.branchOfficeCountryId);
      var result =[];
      available.map(function(x){
        result.push({text:x.visualName, value:x.id});
      });
      this.availableCities=result
		},
		
		async listOfServicePlans(){
			let me=this;
			await me.getObjectResponseWithoutHeaders('api/Parameter/MainServicePlans', me.baseRequest).then(data => {
				if (data !== undefined) {
					me.servicePlans = data.servicePlans; 
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
		},

		async listOfBusinessSectors(){
			let me=this;
			var businessSectorsArray=[];
			await me.getObjectResponseWithoutHeaders('api/Parameter/BusinessSectors', me.baseRequest).then(data => {
				if (data !== undefined) {
					businessSectorsArray = data.businessSectors;
					businessSectorsArray.map(function(x){
            me.businessSectors.push({text:x.visualName, value:x.id});
          });
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
		},

		nextStep() {
			if (this.step === 2) {
				if (!this.$refs.RegistrationMap.validate()) {
					return
				}
			}
			this.step++
		},

    close(){
			this.step=1;
			this.finalStep=1;
      this.$refs.RegistrationForm.reset();
			this.editedCompany = Object.assign({}, this.defaultCompany);
			this.editedItem = Object.assign({}, this.defaultItem);
			this.$refs.RegistrationMap.reset()
		},
		
		async save() {
			let me=this;
			if (!me.$refs.RegistrationMap.validate()) {
				return
			}

			try {
				me.SHOW_LOADING()
				me.formLoading=true;
				let request={
					'visualName': me.editedCompany.visualName,
					'businessSectorId': me.editedCompany.businessSectorId,
					'servicePlanId': me.editedCompany.servicePlanId,
					'branchOfficeCountryId': me.editedCompany.branchOfficeCountryId,
					'branchOfficeCityId': me.editedCompany.branchOfficeCityId,
					'branchOfficeAddress': me.branchOfficeFullAddress,
					'customerServiceSchedule': me.editedCompany.customerServiceSchedule,
					'branchOfficeCoordinates': me.editedCompany.branchOfficeCoordinates,
					
					'nationalityId': me.editedItem.nationalityId,
					'name': me.editedItem.name,
					'surnames': me.editedItem.surnames,
					'gender': me.editedItem.gender,
					'birthdate': me.editedItem.birthdate,
					'documentId': me.editedItem.documentId,
          'documentNumber': me.editedItem.documentNumber,
          'documentComplement': me.editedItem.documentComplement,
          'documentExtensionId': me.editedItem.documentExtensionId,
					'cellPhoneNumber': me.editedItem.cellPhoneNumber,
					'email': me.editedItem.email,
					// 'password': me.editedItem.password,
				};
				await me.getBaseResponseWithoutHeaders('api/Account/Register', request).then(data => {
					if (data) {
						me.finalStep=2;
						me.hideActions=true;
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading=false
			}
			
			if (me.finalStep===2) {
				setTimeout(() => 
				{  
					me.close();
					me.$router.push({ name: 'Login' });
				}, 10000);
			}
    },

		async verifyUserByGDPI(){
      let me=this;
      if (!me.$refs.UserForm.validate()) {
        return;
      }

      try {
        me.SHOW_LOADING()
        me.formLoading=true;
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'gdpi': me.gdpiCode,
        };
        me.formLoading=true;
        await me.getObjectResponseWithoutHeaders('api/Account/CheckIfExistsByGDPI', request).then(data => {
          if (data === undefined) {
            return;
          }
          me.searchPerson.gdpi = me.gdpiCode;
          me.searchPerson.fullName = data.fullName;
          me.searchPerson.exists = data.exists;
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.formLoading=false;
      }
    },

		// async affiliate(){
    //   let me=this;
    //   try {
    //     me.SHOW_LOADING()
    //     me.formLoading=true;
    //     let request={
    //       'companyId': me.baseRequest.companyId,
    //       'userId': me.baseRequest.userId,
    //       'gdpi': me.searchPerson.gdpi,
    //     };
    //     me.formLoading=true;
    //     await me.getObjectResponseWithoutHeaders('api/User/RegisterUsingGDPI', request).then(data => {
    //       if (data === undefined) {
    //         return;
    //       }
    //       me.addOrUpdateListItem(data.user);
    //       me.showSuccessModal();
    //       me.close();
    //       me.editedAccessData.identifier = data.user.identifier;
    //       me.editedAccessData.email = data.user.email;
    //       me.showAccessDataModal = true;
    //     }).catch(function(error){
    //       me.$swal(me.swalConfig.errorTitle, error.message, 'error');
    //     });
    //   } catch (error) {
    //     me.catchError(error)
    //   } finally {
    //     me.HIDE_LOADING()
    //     me.formLoading=false;
    //   }
    // },

		async verifyUserByEmail(){
      let me=this;
      if (!me.$refs.UserForm.validate()) {
        return;
      }

      try {
        me.SHOW_LOADING()
        me.formLoading=true;
        let request={
          'companyId': 1,
          'userId': 1,
          'email': me.searchPerson.email,
        };
        me.formLoading=true;
        await me.getObjectResponseWithoutHeaders('api/Account/CheckIfExistsByEmail', request).then(data => {
          if (data === undefined) {
            return;
          }
          me.searchPerson.gdpi = data.gdpi;
          me.searchPerson.fullName = data.fullName;
          me.searchPerson.exists = data.exists;
          if (!data.exists) {
            me.initialUserForm = false;
						// me.finalStep = 2;
            me.editedItem.email = me.searchPerson.email;
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.formLoading=false;
      }
    },
	},
}
</script>